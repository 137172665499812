export default {
    inject: ['toast'],
    data() {
        return {
            step: 0,
            file: null,
            finished: null,
            progress: 0,
            mountedComponent: false,
        }
    },
    mounted() {
        this.mountedComponent = true
    },
    methods: {
        loadFile() {
            this.$refs.inputfile.click()
        },
        handleChangeFile(e) {
            this.file = e.target.files[0]
            if (this.file) {
                this.step = 1
            } else {
                this.$refs.inputfile.value = null
            }
        },
        checkProgress(token) {
            const event = this.$eventSource('company/import/process?token=' + token)

            event.addEventListener('message', (e) => {
                const parse = JSON.parse(e.data)
                this.progress = parse.percents
                if (parse.percents >= 100) {
                    event.close()
                    this.step = 3
                    this.finished = parse
                }
            })

            event.addEventListener('error', (err) => {
                event.close()
                this.goStart()
            })
        },
        sendFile() {
            this.step = 2
            const formData = new FormData()
            formData.append('file', this.file)
            this.$http.companies
                .importCompany(formData)
                .then((res) => {
                    this.checkProgress(res.data.data.token)
                })
                .catch(({response}) => {
                    this.goStart()
                    if (response.data.errors && response.data.errors.file) {
                        this.toast('error', response.data.errors.file[0])
                    } else {
                        this.toast('error', response.data.message)
                    }
                })
        },
        goStart() {
            this.step = 0
            this.finished = null
            this.file = null
        },
    },
}
