<template>
    <div class="companyImport">
        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem>
                <router-link :to="{ name: 'CompaniesList' }">Companies</router-link>
            </CBreadcrumbItem>

            <CBreadcrumbItem active> Import Companies</CBreadcrumbItem>
        </teleport>

        <CCard>
            <CCardBody>
                <div class="companyImport__body">
                    <h2 class="companyImport__body__title">Company Import Wizard</h2>
                    <div class="companyImport__body__progress">
                        <div class="companyImport__body__progress__circle active" style="cursor: pointer"
                             @click="goStart">
                            <CIcon name="cil-file"/>
                            <div class="companyImport__body__progress__circle-text">
                                Choose Import <br/>
                                Source
                            </div>
                        </div>

                        <div class="companyImport__body__progress__line">
                            <div class="companyImport__body__progress__line-bg"
                                 :style="`width: ${step > 0 ? 100 : 0}%`"/>
                        </div>

                        <CIcon :key="`arrow-1-${step}`" name="cilArrowRight"
                               :class="{ companyImport__body__progress__arrow: true, 'opacity-animation': step === 0 }"
                               style="color: #005d9d"/>

                        <div :class="`companyImport__body__progress__circle${ step > 0 ? ' active' : '' }`">
                            <CIcon name="cil-link"/>
                            <div class="companyImport__body__progress__circle-text">
                                Linking<br/>
                                Process
                            </div>
                        </div>

                        <div class="companyImport__body__progress__line">
                            <div class="companyImport__body__progress__line-bg"
                                 :style="`width: ${step > 2 ? 100 : step > 1 ? progress : 0}%`">
                                <span v-if="step >= 2" style="margin-left: 5px; white-space: nowrap">
                                    {{ progress }}%
                                </span>
                            </div>
                        </div>

                        <CIcon :key="`arrow-2-${step}`" name="cilArrowRight"
                               :class="{ companyImport__body__progress__arrow: true, 'opacity-animation': step === 1 }"
                               :style="`color: ${step > 0 ? '#005D9D' : '#ffffff'}`"/>

                        <div :class="`companyImport__body__progress__circle${ step > 2 ? ' active' : '' }`">
                            <CIcon name="cil-group"/>
                            <div class="companyImport__body__progress__circle-text">
                                Import<br/>
                                Finished
                            </div>
                        </div>
                    </div>

                    <div v-if="step > 1" class="companyImport__body__progress__mob">
                        {{ progress === 100 ? 'Completed' : 'Loading in process. Please wait' }}
                        <div class="companyImport__body__progress__mob__line">
                            <div class="companyImport__body__progress__mob__line__progress"
                                 :style="`width: ${progress}%`">
                                {{ progress }}%
                            </div>
                        </div>
                    </div>

                    <div v-if="step === 0" class="companyImport__body__modal">
                        <h2 class="companyImport__body__modal__title">Choose Company Import Source</h2>
                        <CButton color="primary" class="companyImport__body__modal__btn" @click="loadFile">
                            <CIcon name="cil-file"/>
                            Select Companies File
                        </CButton>
                        <input ref="inputfile" type="file" accept=".xls, .xlsx" hidden @change="handleChangeFile"/>
                    </div>

                    <div v-if="step === 1" class="companyImport__body__modal" style="margin-bottom: 160px">
                        <h2 class="companyImport__body__modal__title">
                            Linking Companies to your account and sending invites
                        </h2>
                        <CButton color="primary" class="companyImport__body__modal__btn" @click="sendFile">
                            Start Linking Process
                        </CButton>
                    </div>

                    <div v-if="step === 2" class="companyImport__body__modal companyImport__body__modal__spinner"
                         style="height: 300px; justify-content: center">
                        <CSpinner class="companyImport__body__modal__spinner" color="primary"/>
                    </div>

                    <div v-if="step === 3" class="companyImport__body__modal">
                        <h2 class="companyImport__body__modal__title">
                            {{ finished.connected_companies_count }} Companies Linked,
                            {{ finished.invited_companies_count }} Invites Send
                        </h2>
                        <CButton color="primary" class="companyImport__body__modal__btn"
                                 @click="$router.push({ name: 'CompaniesList' })">
                            View Companies
                        </CButton>
                        <h2 class="companyImport__body__modal__subtitle">Import Summary</h2>
                        <template v-if="finished">
                            <p class="companyImport__body__modal__text">
                                {{ finished.connected_companies_count }} of
                                {{ finished.total_rows_count }} Companies Successfully synced to
                                your list of Companies
                            </p>
                            <p class="companyImport__body__modal__text">
                                {{ finished.invited_companies_count }} Invitations have been
                                sent to join Contracts Connected
                            </p>
                            <p class="companyImport__body__modal__text">
                                Invited companies are added to your Companies after sign up
                            </p>
                        </template>
                    </div>

                    <div class="companyImport__body__footer">
                        <div class="companyImport__body__footer__link">
                            Need help getting started?
                        </div>
                        <div class="companyImport__body__footer__link">
                            <span class="companyImport__body__footer__link-item">View import guide</span>
                            <a href="/companies.xls" class="companyImport__body__footer__link-item">
                                Download sample spreadsheet
                            </a>
                            <span class="companyImport__body__footer__link-item">View the FAQ</span>
                        </div>
                    </div>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
